<template>
    <iframe :src="configuracionReceta"></iframe>
</template>
<style scoped>
    iframe{
        height: 100vh;
        width: 100vw;
        border: 0;
        margin-top: 0px;
        margin-left:0px;
    }
</style>
<script>
export default {
    data:function(){
        return {

        }
    },

    computed:{
        configuracionReceta:function(){
            let at = this.$store.state.usuario ? this.$store.state.usuario.sessionUuid:null

            if(at && at != null){
                return process.env.VUE_APP_RECETA_MANIK_URL+'/#/home?at='+at+'&client=DM';
                //return 'https://192.168.1.91:8088/RecetaManik/#/home?at='+at+'&client=DM';
            }


        }
    }


}
</script>